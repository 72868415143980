import axios from "axios"
axios.defaults.withCredentials = true

export const signup = async (user) =>
  await axios.post(process.env.REACT_APP_SERVER_URI + "/api/signup", user)
  
export const addGender = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/attachGoogleReferral",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: true,
      },
    },
);

export const checkGender = async (userId) =>
  await axios.post(process.env.REACT_APP_SERVER_URI + "/api/validateAvatar", {userId})
  
export const checkRegistered = async (email) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/checkRegistered",
    email
  )

export const checkOTP = async (otpData) =>
  await axios.post(process.env.REACT_APP_SERVER_URI + "/api/checkOTP", otpData)

export const signin = async (user) =>
  await axios.post(process.env.REACT_APP_SERVER_URI + "/api/signin", user, {
    withCredentials: true,
  })

export const getUserProfile = async (token) =>
  await axios.get(process.env.REACT_APP_SERVER_URI + "/api/userProfile", {
    headers: {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    },
  })

export const updatePassword = async (data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/updatePassword",
    data,
    {
      withCredentials: true,
    }
  )

export const checkForgotOTP = async (otp) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/checkForgotOTP",
    otp,
    {
      withCredentials: true,
    }
  )

export const sendForgotOTP = async (email) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/forgotPassword",
    email,
    {
      withCredentials: true,
    }
  )

export const refreshToken = async () =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/refreshToken",
    {},
    {
      withCredentials: true,
    }
  )

export const logout = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/logout",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: true,
      },
    }
  )

export const checkEmail = async (email) =>
  await axios.post(process.env.REACT_APP_SERVER_URI + "/api/checkemail", email)

export const corporateSignUp = async (data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/corporate/signup",
    data
  )

export const sendOTP = async (data) =>
  await axios.post(process.env.REACT_APP_SERVER_URI + "/api/sendOTP", data)
